import cookies from "js-cookie";

function formatPrice(num) {
    if (num % 100 == 0) {
        return num / 100;
    }
    return (num / 100).toFixed(2);
}

function format1000Price(num) {
    return num / 1000;
}
const selectOptionTime = () => {
    return [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
        22, 23, 24,
    ];
};
const getCookieUserKey = (key) => {
    var cuser = cookies.get("userInfo");
    if (cuser) {
        var obj = JSON.parse(decodeURI(cuser));
        return obj[key];
    } else {
        return "";
    }
};
const getQueryParam = (name) => {
    try {
        let value = "";
        let query = window.location.hash
            .substring(
                window.location.hash.indexOf("?") + 1,
                window.location.hash.length
            )
            .split("&");
        query.forEach((item) => {
            if (item.indexOf(name + "=") > -1) {
                value = decodeURIComponent(item.replace(name + "=", ""));
                if (!value) {
                    value = "";
                }
            }
        });
        return value;
    } catch (error) {
        return "";
    }
};

const getOrderState = (state, refund_amout) => {
    let value = "";
    if (state == 0) {
        value = "待支付";
    } else if (state == 1) {
        value = "执行中";
    } else if (state == 3) {
        value = "暂停";
    } else if (state == 9) {
        value = "问题单，请联系客服";
    } else if (state == 4) {
        value = "排队中";
    } else {
        value = "已完成";
    }
    if (refund_amout > 0) {
        value += "，有退费";
    }
    return value;
};

const getIsAgent = (isgent) => {
    let value = "";
    if (isgent == 0) {
        value = "普通用户";
    } else if (isgent == 1) {
        value = "战略代理";
    } else if (isgent == 2) {
        value = "分销代理";
    } else if (isgent == 3) {
        value = "普通代理";
    }
    return value;
};
const getUserState = (userState) => {
    let value = "";
    if (userState == 0) {
        value = "停用";
    } else if (userState == 1) {
        value = "启用";
    }
    return value;
};

const isMobile = () => {
    var phone =
        /Android|webOS|ipad|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );
    // phone = false;
    return phone;
};
export default {
    formatPrice,
    selectOptionTime,
    getQueryParam,
    format1000Price,
    getCookieUserKey,
    getOrderState,
    isMobile,
    getIsAgent,
    getUserState,
};