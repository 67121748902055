import axios from "axios";
import buildURL from "../../node_modules/axios/lib/helpers/buildURL";
import utils from "../../node_modules/axios/lib/utils";
import loading from "@/common/loading.js";
import { message } from "ant-design-vue";
import cookies from "js-cookie";
import util from "@/common/util";

function isPlainObject(val) {
    return val && val.constructor.name === "Object";
}

const merge = utils.merge;
let requestInterceptors = [
    (config) => {
        if (config.isloading) {
            loading.show();
        }
        if (!config.data) {
            config.data = {};
        }
        if (!config.data.param) {
            config.data.param = {};
        }
        let token = cookies.get("token") || "";
        let userid = cookies.get("userid") || 0;
        let userkey = cookies.get("userkey") || "";
        config.data.param.token = token;
        if (!config.data.param.userid) {
            config.data.param.userid = userid;
        }
        if (!config.data.param.user_key) {
            config.data.param.user_key = cookies.get("userkey");
        }
        config.headers["Token"] = token;
        config.headers["User-Id"] = userid;
        config.headers["User-Key"] = userkey;
        if (config.emulateJSON && isPlainObject(config.data)) {
            config.data = buildURL("", config.data).substring(1);
        }
        return config;
    },
];

let responseInterceptors = [
    (res) => {
        loading.hide();
        if (
            window.location.hash.toLowerCase() !== "#/login" &&
            window.location.hash.toLowerCase() !== "#/mobile/login" &&
            (res.data.ret_code == 401 || res.data.ret_code == 402)
        ) {
            message.error(res.data.ret_msg);
            if (util.isMobile()) {
                window.location.href = "/#/mobile/login";
            } else {
                window.location.href = "/#/login";
            }
        } else {
            if (res.data.ret_code == 400 || res.data.ret_code == 500) {
                message.error(res.data.ret_msg);
            }
        }
        return Promise.resolve(res.data);
    },
    (err) => {
        loading.hide();
        message.error(err.message);
        return Promise.reject(err);
    },
];

let serviceCreate = (config) => {
    let service = axios.create(merge({}, config));
    service.interceptors.request.use(...requestInterceptors);
    service.interceptors.response.use(...responseInterceptors);
    return service;
};
serviceCreate();
export { serviceCreate, merge };