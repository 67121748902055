<template>
  <a-config-provider :locale="zh_CN">
    <div id="appWarp"><router-view></router-view></div>
  </a-config-provider>
</template>

<script>
import util from "@/common/util";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  components: {},
  data() {
    return {
      zh_CN: zh_CN,

    };
  },
  created() { },
  mounted() { },
  methods: {

    getPath() {
      let path = this.$route.path;
      console.log(path)
      if (util.isMobile()) {
        //页面为PC页面
        if (path?.toLowerCase().indexOf("mobile") == -1) {
          window.location.href = "/#/mobile/home";
        }
      } else {
        console.log("PC");
        //页面为手机页面
        if (path?.toLowerCase().indexOf("mobile") == 1) {
          window.location.href = "/#/Index/HomePage";
        }
      }
    }
  },
  watch: {
    //监听路由
    '$route': 'getPath'
  },
};
</script>
<style lang="scss">
// :root {
//   font-size: 18px;
// }

#appWarp {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0px;
  padding: 0xp;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
