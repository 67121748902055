import { postDataJson, getData } from "../api";

function Login(param) {
    return postDataJson("/api/User/Login", { param: param });
}

function RegisterUser(param) {
    return postDataJson("/api/User/RegisterUser", { param: param });
}

function ConfigInfo(param) {
    return postDataJson("/api/User/ConfigInfo", { param: param });
}

function UserAmout(param) {
    return postDataJson("/api/User/UserAmout", { param: param });
}

function Transactions(param) {
    return postDataJson("/api/User/Transactions", { param: param });
}

function UserAgentList(param) {
    return postDataJson("/api/User/UserAgentList", { param: param });
}

function UserIsAgent(param) {
    return postDataJson("/api/User/UserIsAgent", { param: param });
}

function AmountTransfer(param) {
    return postDataJson("/api/User/AmountTransfer", { param: param });
}

function TestAuth(param) {
    return postDataJson("/api/User/TestAuth", { param: param });
}

function GetPayData(param) {
    return postDataJson("/api/User/GetPayData", { param: param });
}

function paycallbackTest(param = {}) {
    return getData("/api/User/paycallbackTest", param);
}

function GetPayOrderState(param = {}) {
    return postDataJson("/api/User/GetPayOrderState", param, {
        isloading: false,
    });
}

function GetProxyBenefit(param) {
    return postDataJson("/api/User/GetProxyBenefit", { param: param });
}

function GetConsumeList(param) {
    return postDataJson("/api/User/GetConsumeList", { param: param });
}

function GetUserWithdrawal(param) {
    return postDataJson("/api/User/GetUserWithdrawal", { param: param });
}

function GetUserBankInfo(param) {
    return postDataJson("/api/User/GetUserBankInfo", { param: param });
}

function CreateUserBank(param) {
    return postDataJson("/api/User/CreateUserBank", { param: param });
}

function RecordLog(param) {
    return postDataJson("/api/User/RecordLog", { param: param });
}

function GetWithdrawTotalAmount(param) {
    return postDataJson("/api/User/GetWithdrawTotalAmount", { param: param });
}

function CreateWithdrawal(param) {
    return postDataJson("/api/User/CreateWithdrawal", { param: param });
}

function GetMarginBenfitInfo(param) {
    return postDataJson("/api/User/GetMarginBenfitInfo", { param: param });
}

function GetProxyBenefitInfo(param) {
    return postDataJson("/api/User/GetProxyBenefitInfo", { param: param });
}

function GetCoustomService(param) {
    return postDataJson("/api/User/GetCoustomService", { param: param });
}

function AgentProportionSettings(param) {
    return postDataJson("/api/User/AgentProportionSettings", { param: param });
}

function AddCoustomService(param) {
    return postDataJson("/api/User/AddCoustomService", { param: param });
}

function GetProxyService(param) {
    return postDataJson("/api/User/GetProxyService", { param: param });
}

function GetProxyConfigList(param) {
    return postDataJson("/api/User/GetProxyConfigList", { param: param });
}

function CreateProxyConfig(param) {
    return postDataJson(
        "/api/User/CreateProxyConfig", { param: param }, {
            isloading: true,
        }
    );
}

function ModifPwd(param) {
    return postDataJson("/api/User/ModifPwd", { param: param });
}

function ProxyCreateChildrenUser(param) {
    return postDataJson("/api/User/ProxyCreateChildrenUser", { param: param });
}

function UpdateState(param) {
    return postDataJson(
        "/api/User/UpdateState", { param: param }, {
            isloading: true,
        }
    );
}

export default {
    Login,
    RegisterUser,
    ConfigInfo,
    UserAmout,
    Transactions,
    UserAgentList,
    UserIsAgent,
    AmountTransfer,
    ModifPwd,
    TestAuth,
    GetPayData,
    paycallbackTest,
    AgentProportionSettings,
    GetPayOrderState,
    GetProxyBenefit,
    GetConsumeList,
    GetUserWithdrawal,
    GetUserBankInfo,
    CreateUserBank,
    ProxyCreateChildrenUser,
    RecordLog,
    GetWithdrawTotalAmount,
    CreateWithdrawal,
    GetMarginBenfitInfo,
    GetProxyBenefitInfo,
    GetCoustomService,
    AddCoustomService,
    GetProxyService,
    GetProxyConfigList,
    CreateProxyConfig,
    UpdateState,
};